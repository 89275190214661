import { ReactNode } from 'react';
import './Card.scss';

export default function Card({children, className}: CardProps): JSX.Element {
	if(!className){
		className= '';
	}
    
	return (
		<div className={'card '+ className}>
			{children}
		</div>
	);
}

interface CardProps {
    children: ReactNode | ReactNode[];
    className?: string;
}