import { useTranslation } from 'react-i18next';
import Card from '../../../../components/card/Card';
import './AboutMeCard.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';

export default function AboutMeCard(): JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const onAboutMeClick = () : void => {
		navigate('/over-ons');
	};

	return (
		<section className='aboutMeCard'>
			<Card>
				<div className='aboutMeCard__container'>
					<div className='aboutMeCard__image'>
						<img src='./images/profile.webp' alt='Loes Beetz' />
					</div>
					<div className='aboutMeCard__textContainer'>
						<h2 className='aboutMeCard__header'>{t('home.aboutMeCard.header')}</h2>
						<p className='aboutMeCard__text'>{t('home.aboutMeCard.text')}</p>
						<Button style='outlined' onClick={onAboutMeClick} className='aboutMeCard__button'>{t('home.aboutMeCard.buttonText')}</Button>
					</div>
				</div>
			</Card>
		</section>
	);
}