import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import './CallToActionRow.scss';

export default function CallToActionRow() : JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const onButtonClick = (): void => {
		navigate('/contact');
	};

	return (
		<section className='callToActionRow'>
			<div className='callToActionRow__container'>
				<h2 className='callToActionRow__header'>{t('home.callToActionRow.header')}</h2>
				<p className='callToActionRow__text'>{t('home.callToActionRow.text')}</p>
				<Button className='callToActionRow__button' onClick={onButtonClick}>{t('home.callToActionRow.buttonText')}</Button>
			</div>
		</section>
	);
}