import { t } from 'i18next';
import './ContactHeader.scss';

export default function ContactHeader() : JSX.Element {
	return (
		<div>
			<h1 className='contactHeader__title'>{t('contact.header.title')}</h1>
			<p>{t('contact.header.subTitle')}</p>
		</div>
	);
}