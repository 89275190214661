import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './Footer.scss';
import Logo from '../logo/Logo';

export default function Footer(): JSX.Element {
	const { t } = useTranslation();
	const phoneLink = `tel:${t('phone')}`;
	const emaiLink = `mailto:${t('email')}`;
	const whatsappLink = `https://wa.me/${t('whatsappPhone')}?text=${encodeURI(t('whatsappMessage'))}`;

	return(
		<section className='footer'>
			<NavLink className='footer__title' aria-label="Naar de homepage" to="/">
				<Logo className='footer__logo' />
			</NavLink>
			<div className='footer__content'>
				<div className='footer__address'>
					<p className='footer__addressHeader'>{t('footer.content.address.header')}</p>
					<p>{t('footer.content.address.street')}</p>
					<p>{t('footer.content.address.city')}</p>
					<p>{t('footer.content.address.postalCode')}</p>
				</div>
				<div className='footer__links'>
					<p className='footer__linksHeader'>{t('footer.content.links.header')}</p>
					<NavLink to="/">{t('footer.content.links.home')}</NavLink>
					<NavLink to="/over-ons">{t('footer.content.links.about')}</NavLink>
					<NavLink to="/contact">{t('footer.content.links.contact')}</NavLink>
				</div>
				<div className='footer__contact'>
					<p className='footer__contactHeader'>{t('footer.content.contact.header')}</p>
					<a href={whatsappLink}>{t('footer.content.contact.whatsapp')}</a>
					<a href={emaiLink}>{t('email')}</a>
					<a href={phoneLink}>{t('phone')}</a>
				</div>
			</div>
			<hr/>
			<p className='footer__footNote'>{t('footer.footNote', {date: new Date().getFullYear()})}</p>
		</section>
	);
}