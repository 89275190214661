import './App.scss';
import { Route, Routes } from 'react-router';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
	return (
		<div className="app">
			<Navbar />
			<main className='app__main'>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/over-ons' element={<About />} />
					<Route path='/contact' element={<Contact />} />
				</Routes>
			</main>
			<Footer />
		</div>
	);
}

export default App;
