import AboutMeCard from './components/aboutMeCard/AboutMeCard';
import CallToActionFooter from './components/callToActionFooter/CallToActionFooter';
import CallToActionRow from './components/callToActionRow/CallToActionRow';
import Hero from './components/hero/Hero';
import Treatments from './components/treatments/Treatments';

export default function Home(): JSX.Element {
	return (
		<>
			<Hero/>
			<Treatments/>
			<CallToActionRow/>
			<AboutMeCard/>
			<CallToActionFooter/>
		</>
	);
}