import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Navbar.scss';
import Logo from '../logo/Logo';
import { useState } from 'react';

export default function Navbar(): JSX.Element {
	const { t } = useTranslation();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isMobile = useMediaQuery({ maxWidth: '1150px' });

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	
	const closeMobileMenu = () => {
		if (isMobile) {
			setIsMenuOpen(false);
		}
	};

	const renderNavLinks = () => {
		const listClassName = isMobile ? 'nav__list' : 'nav__list__web';
		const linkClassName = 'nav__link';

		return (
			<ul className={listClassName}>
				{isMobile && <li >
					<NavLink to="/" aria-label="Naar de homepage">
						<Logo className='nav__logo' />
					</NavLink>
				</li>
				}
				<li><NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>{t('navbar.links.home')}</NavLink></li>
				<li><NavLink to="/over-ons" className={linkClassName} onClick={closeMobileMenu}>{t('navbar.links.about')}</NavLink></li>
				<li><NavLink to="/contact" className={linkClassName} onClick={closeMobileMenu}>{t('navbar.links.contact')}</NavLink></li>
			</ul>
		);
	};

	return (
		<header>
			<nav className='nav'>
				<NavLink to="/" aria-label="Naar de homepage">
					<Logo className='nav__logo' />
				</NavLink>
				{isMobile && (
					<div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
						<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.05663 17.7871C0.769289 17.7871 0.528428 17.6462 0.334049 17.3642C0.13967 17.0822 0.0424805 16.7328 0.0424805 16.316C0.0424805 15.8992 0.13967 15.5498 0.334049 15.2678C0.528428 14.9859 0.769289 14.8449 1.05663 14.8449H17.2831C17.5704 14.8449 17.8113 14.9859 18.0056 15.2678C18.2 15.5498 18.2972 15.8992 18.2972 16.316C18.2972 16.7328 18.2 17.0822 18.0056 17.3642C17.8113 17.6462 17.5704 17.7871 17.2831 17.7871H1.05663ZM1.05663 10.4315C0.769289 10.4315 0.528428 10.2905 0.334049 10.0086C0.13967 9.72661 0.0424805 9.37722 0.0424805 8.9604C0.0424805 8.54358 0.13967 8.19419 0.334049 7.91223C0.528428 7.63026 0.769289 7.48928 1.05663 7.48928H17.2831C17.5704 7.48928 17.8113 7.63026 18.0056 7.91223C18.2 8.19419 18.2972 8.54358 18.2972 8.9604C18.2972 9.37722 18.2 9.72661 18.0056 10.0086C17.8113 10.2905 17.5704 10.4315 17.2831 10.4315H1.05663ZM1.05663 3.07591C0.769289 3.07591 0.528428 2.93493 0.334049 2.65296C0.13967 2.371 0.0424805 2.02161 0.0424805 1.60479C0.0424805 1.18797 0.13967 0.83858 0.334049 0.556615C0.528428 0.27465 0.769289 0.133667 1.05663 0.133667H17.2831C17.5704 0.133667 17.8113 0.27465 18.0056 0.556615C18.2 0.83858 18.2972 1.18797 18.2972 1.60479C18.2972 2.02161 18.2 2.371 18.0056 2.65296C17.8113 2.93493 17.5704 3.07591 17.2831 3.07591H1.05663Z" fill="#050316e6" />
						</svg>
					</div>
				)}
				{isMobile ? (
					<div
						className={`nav__menu  ${isMenuOpen ? 'show-menu' : ''}`}
						id="nav-menu"
					>
						{renderNavLinks()}
						<div className="nav__close" id="nav-close" onClick={toggleMenu}>
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
						</div>
					</div>
				) : (
					renderNavLinks()
				)}
			</nav>
		</header>
	);
}