import { useTranslation } from 'react-i18next';
import Card from '../../../../components/card/Card';
import './Treatments.scss';

export default function Treatments(): JSX.Element {
	return (
		<section className='treatments'>
			<TreatmentCard translationKey='home.treatments.card1' icon='podiatry' />
			<TreatmentCard translationKey='home.treatments.card2' icon='rheumatology' />
			<TreatmentCard translationKey='home.treatments.card3' icon='femur_alt'/>
			<TreatmentCard translationKey='home.treatments.card4' icon='physical_therapy'/>
		</section>
	);
}

function TreatmentCard({ translationKey, icon }: TreatmentCardProps): JSX.Element {
	const { t } = useTranslation();

	return (
		<Card className='treatments__card'>
			<span className='treatments__icon material-symbols-rounded'>
				{icon}
			</span>
			<p>{t(translationKey)}</p>
		</Card>
	);
}

interface TreatmentCardProps {
	translationKey: string
	icon: string
}