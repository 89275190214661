import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import './Hero.scss';
import { useTranslation } from 'react-i18next';

export default function Hero(): JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const onButtonClick = (): void => {
		navigate('/contact');
	};

	return (
		<section className='hero'>
			<div className='hero__textContainer'>
				<div>
					<h1 className='hero__header'>{t('home.hero.title')}</h1>
					<h2 className='hero__slogan'>{t('slogan')}</h2>
					<p className='hero__text'>{t('home.hero.text')}</p>
				</div>
				<Button className='hero__callToAction' onClick={onButtonClick}>{t('home.hero.callToAction')}</Button>
			</div>
			<div>
				<img className='hero__image' src='./images/hero.webp' alt='Hardlopen' />
				<div className='hero__svgContainer'>
					<svg className='hero__svg hero__svg1' xmlns="http://www.w3.org/2000/svg" width="119" height="119" viewBox="0 0 119 119" fill="none">
						<g filter="url(#filter0_d_14_86)">
							<path d="M106.584 53.4399C106.584 81.5027 83.8344 104.252 55.7717 104.252C27.7089 104.252 4.95947 81.5027 4.95947 53.4399C4.95947 25.3771 27.7089 2.62769 55.7717 2.62769C83.8344 2.62769 106.584 25.3771 106.584 53.4399Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_14_86" x="0.959473" y="0.627686" width="117.625" height="117.624" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_86" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_86" result="shape" />
							</filter>
						</defs>
					</svg>
					<svg className='hero__svg hero__svg2' xmlns="http://www.w3.org/2000/svg" width="92" height="91" viewBox="0 0 92 91" fill="none">
						<g filter="url(#filter0_d_14_88)">
							<path d="M79.3756 39.5042C79.3756 60.2172 62.5844 77.0085 41.8714 77.0085C21.1584 77.0085 4.36719 60.2172 4.36719 39.5042C4.36719 18.7912 21.1584 2 41.8714 2C62.5844 2 79.3756 18.7912 79.3756 39.5042Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_14_88" x="0.367188" y="0" width="91.0083" height="91.0084" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_88" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_88" result="shape" />
							</filter>
						</defs>
					</svg>
					<svg className='hero__svg hero__svg3' xmlns="http://www.w3.org/2000/svg" width="118" height="119" viewBox="0 0 118 119" fill="none">
						<g filter="url(#filter0_d_14_87)">
							<path d="M106 53.3096C106 81.3724 83.2505 104.122 55.1877 104.122C27.1249 104.122 4.37549 81.3724 4.37549 53.3096C4.37549 25.2468 27.1249 2.49744 55.1877 2.49744C83.2505 2.49744 106 25.2468 106 53.3096Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_14_87" x="0.375488" y="0.497437" width="117.625" height="117.624" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_87" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_87" result="shape" />
							</filter>
						</defs>
					</svg>
					<svg className='hero__svg hero__svg4' xmlns="http://www.w3.org/2000/svg" width="154" height="153" viewBox="0 0 154 153" fill="none">
						<g filter="url(#filter0_d_14_89)">
							<path d="M141.327 70.4763C141.327 108.227 110.723 138.831 72.9722 138.831C35.221 138.831 4.61768 108.227 4.61768 70.4763C4.61768 32.7252 35.221 2.12183 72.9722 2.12183C110.723 2.12183 141.327 32.7252 141.327 70.4763Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_14_89" x="0.617676" y="0.121826" width="152.709" height="152.709" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_89" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_89" result="shape" />
							</filter>
						</defs>
					</svg>
					<svg className='hero__svg hero__svg5' xmlns="http://www.w3.org/2000/svg" width="153" height="153" viewBox="0 0 153 153" fill="none">
						<g filter="url(#filter0_d_13_40)">
							<path d="M140.977 70.3917C140.977 108.143 110.373 138.746 72.6221 138.746C34.8709 138.746 4.26758 108.143 4.26758 70.3917C4.26758 32.6406 34.8709 2.03723 72.6221 2.03723C110.373 2.03723 140.977 32.6406 140.977 70.3917Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_13_40" x="0.267578" y="0.0372314" width="152.709" height="152.709" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13_40" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13_40" result="shape" />
							</filter>
						</defs>
					</svg>
					<svg className='hero__svg hero__svg6' xmlns="http://www.w3.org/2000/svg" width="85" height="86" viewBox="0 0 85 86" fill="none">
						<g filter="url(#filter0_d_14_90)">
							<path d="M72.9594 36.7893C72.9594 55.8319 57.5223 71.269 38.4797 71.269C19.4371 71.269 4 55.8319 4 36.7893C4 17.7467 19.4371 2.30957 38.4797 2.30957C57.5223 2.30957 72.9594 17.7467 72.9594 36.7893Z" fill="currentColor" />
						</g>
						<defs>
							<filter id="filter0_d_14_90" x="0" y="0.30957" width="84.9595" height="84.9594" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="4" dy="6" />
								<feGaussianBlur stdDeviation="4" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_90" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_90" result="shape" />
							</filter>
						</defs>
					</svg>
				</div>
			</div>
		</section>);
}