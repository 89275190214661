import './Contact.scss';
import ContactCards from './components/contactCards/ContactCards';
import ContactHeader from './components/contactHeader/ContactHeader';

export default function Contact(): JSX.Element {
	return (
		<div className='contactPage'>
			<ContactHeader />
			<ContactCards />
		</div>
	);
}