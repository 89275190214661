import { ReactNode } from 'react';
import './Button.scss';

export default function Button({ children, onClick, className, style }: IButtonProps): JSX.Element {
	if (!style) {
		style = 'filled';
	}
	if (!className) {
		className = '';
	}

	className = `button button__${style} ` + className;

	return (
		<button className={className} type='button' onClick={onClick}>{children}</button>
	);
}

interface IButtonProps {
	children: ReactNode | ReactNode[];
	className?: string;
	style?: 'filled' | 'outlined'
	onClick?: () => void
}